import HomePage from './views/homePage';
import AboutUsPage from './views/aboutUsPage';
import ContactPage from './views/contactPage';
import BlogPage from './views/blogPage';
import BlogDetailPage from './views/blogDetailPage';
import ProductionProcess from './views/productionProcess';
import GalleryPage from './views/galleryPage';
import Documents from './views/documents';

const linkTranslates = {

    'en': {
        '/': '/en',
        '/o-nama/:alias': '/en/about-us/:alias',
        '/o-nama' : '/en/about-us',
        '/novosti': '/en/blog',
        '/novosti/:alias': '/en/blog/:alias',
        '/kontakt': '/en/contact',
        '/galerija': '/en/gallery',
        '/dokumenta': "/en/documents"
    },
}

export const routes = [

    {
        path: "/",
        component: HomePage,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Početna'.translate(lang) + ' - BIO ENERGO | Pratimo prirodu, stvaramo energiju.'.translate(lang),
                description: 'Bioenergo PARTNER MD je biogasno postrojenje koje koristi resurse sela kao izvor za proizvodnju električne i toplotne energije.'.translate(lang),
                'og:image': 'https://bioenergo.novamedia.agency/social.png'
            }
        },
        loadData: [
            (fetchFunction, lang, match) => {
                return fetchFunction('https://bioenergo-api.novamedia.agency/home', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    }
                }).then(res => res.json()).then((data) => {
                    return {
                        data
                    }
                })

            },
            (fetchFunction, lang, match) => {
                return fetchFunction('https://bioenergo-api.novamedia.agency/services', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },

                }).then(res => res.json()).then((result) => {

                    return {
                        services: result,
                    }
                })

            },
            (fetchFunction, lang, match) => {
                return fetchFunction('https://bioenergo-api.novamedia.agency/blog/latest', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },

                }).then(res => res.json()).then((result) => {

                    return {
                        latestBlog: result,
                    }
                })

            },
            (fetchFunction, lang, match) => {
                return fetchFunction('https://bioenergo-api.novamedia.agency/gallery', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    }
                }).then(res => res.json()).then((gallery) => {
                    return {
                        gallery
                    }
                })

            }

        ]

    },
    
    {
        path: "/o-nama/:alias",
        component: ProductionProcess,
        generateSeoTags: (data, lang) => {
            return {
                title: Object.translate(data, 'data.name', lang)  + ' - BIO ENERGO | Pratimo prirodu, stvaramo energiju.'.translate(lang),
                description: 'Bioenergo PARTNER MD je biogasno postrojenje koje koristi resurse sela kao izvor za proizvodnju električne i toplotne energije.'.translate(lang),
                'og:image': 'https://bioenergo.novamedia.agency/social.png'

            }
        },
        loadData: [
            (fetchFunction, lang, match) => {

                return fetchFunction(`https://bioenergo-api.novamedia.agency/services/${lang}/${match.params.alias}`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    }
                }).then(res => res.json()).then((data) => {
                    return {
                        data
                    }
                })

            },
            (fetchFunction, lang, match) => {
                return fetchFunction('https://bioenergo-api.novamedia.agency/blog/latest', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },

                }).then(res => res.json()).then((result) => {

                    return {
                        latestBlog: result,
                    }
                })

            }



        ]

    },
    /*{
        path: "/o-nama",
        component: AboutUsPage,
        loadData: [
            (fetchFunction, lang, match) => {
                return fetchFunction('https://bioenergo-api.novamedia.agency/aboutus', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    }
                }).then(res => res.json()).then((data) => {
                    return {
                        data
                    }
                })

            }


        ]

    },*/
    {
        path: "/novosti",
        component: BlogPage,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Novosti'.translate(lang)  + ' - BIO ENERGO | Pratimo prirodu, stvaramo energiju.'.translate(lang),
                description: 'Bioenergo PARTNER MD je biogasno postrojenje koje koristi resurse sela kao izvor za proizvodnju električne i toplotne energije.'.translate(lang),
                'og:image': 'https://bioenergo.novamedia.agency/social.png'

            }
        },

        loadData: [

            (fetchFunction, lang, match) => {
                return fetchFunction('https://bioenergo-api.novamedia.agency/blog/latest', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },

                }).then(res => res.json()).then((result) => {

                    return {
                        latestBlog: result,
                    }
                })

            }


        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {
                return fetchFunction('https://bioenergo-api.novamedia.agency/blog', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        lang: lang
                    })
                }).then(res => res.json()).then((result) => {
                    return {
                        page: query.page ? query.page : 0,
                        items: result.items,
                        total: result.total
                    }
                })

            }
        ]
    },

    {
        path: "/novosti/:alias",
        generateSeoTags: (data, lang) => {
            console.log(data.title);
            return {
                title:  Object.translate(data, 'data.title', lang) + ' | ' + 'Novosti'.translate(lang)  + ' - BIO ENERGO | Pratimo prirodu, stvaramo energiju.'.translate(lang),
                description: Object.translate(data, 'data.shortDescriprion', lang),
                'og:image': Object.get(data, 'data.image')
            }
        },
        component: BlogDetailPage,
        loadData: [
            (fetchFunction, lang, match) => {
                return fetchFunction('https://bioenergo-api.novamedia.agency/blog/latest', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },

                }).then(res => res.json()).then((result) => {

                    return {
                        latestBlog: result,
                    }
                })

            },
            (fetchFunction, lang, match) => {
                return fetchFunction(`https://bioenergo-api.novamedia.agency/blog/detail/${lang}/${match.params.alias}`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },

                }).then(res => res.json()).then((result) => {

                    return {
                        data: result,
                    }
                })

            }




        ]

    },


    {
        path: "/kontakt",
        component: ContactPage,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Kontakt'.translate(lang)  + ' - BIO ENERGO | Pratimo prirodu, stvaramo energiju.'.translate(lang),
                description: 'Bioenergo PARTNER MD je biogasno postrojenje koje koristi resurse sela kao izvor za proizvodnju električne i toplotne energije.'.translate(lang),
                'og:image': 'https://bioenergo.novamedia.agency/social.png'

            }
        },
        loadData: [

            (fetchFunction, lang, match) => {
                return fetchFunction(`https://bioenergo-api.novamedia.agency/informations`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },

                }).then(res => res.json()).then((result) => {

                    return {
                        data: result,
                    }
                })

            },
        ]

    },
    {
        path: "/dokumenta",
        component: Documents,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Dokumenta'.translate(lang)  + ' - BIO ENERGO | Pratimo prirodu, stvaramo energiju.'.translate(lang),
                description: 'Bioenergo PARTNER MD je biogasno postrojenje koje koristi resurse sela kao izvor za proizvodnju električne i toplotne energije.'.translate(lang),
                'og:image': 'https://bioenergo.novamedia.agency/social.png'

            }
        },

        loadData: [
            
            (fetchFunction, lang, match) => {
                return fetchFunction(`https://bioenergo-api.novamedia.agency/documents`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },

                }).then(res => res.json()).then((result) => {

                    return {
                        data: result,
                    }
                })

            },
            (fetchFunction, lang, match) => {
                return fetchFunction(`https://bioenergo-api.novamedia.agency/informations`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },

                }).then(res => res.json()).then((result) => {

                    return {
                        info: result,
                    }
                })

            },
        ]
    },
    {
        path: "/galerija",
        component: GalleryPage,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Galerija'.translate(lang)  + ' - BIO ENERGO | Pratimo prirodu, stvaramo energiju.'.translate(lang),
                description: 'Bioenergo PARTNER MD je biogasno postrojenje koje koristi resurse sela kao izvor za proizvodnju električne i toplotne energije.'.translate(lang),
                'og:image': 'https://bioenergo.novamedia.agency/social.png'

            }
        },
        loadData: [
            (fetchFunction, lang, match) => {
                return fetchFunction('https://bioenergo-api.novamedia.agency/gallery', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    }
                }).then(res => res.json()).then((data) => {
                    return {
                        gallery: data.images
                    }
                })

            }
        ]

    },
];

export function generateRoutes(r) {
    let newRoutes = [];
    for (let i = 0; i < r.length; i++) {
        newRoutes.push(r[i]);

        newRoutes.push({
            ...r[i],
            path: linkTranslates.en[r[i].path]
        })
    }
    return newRoutes;
}