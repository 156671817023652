import React, { Component } from 'react';
import Link from '../components/link';

import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown
} from 'reactstrap';

import slika5 from '../assets/images/slika5.png';
import home from '../assets/images/home.png';
import home2 from '../assets/images/home2.png';

import facebook from '../assets/svg/facebook.svg';
import instagram from '../assets/svg/instagram.svg';

import Map from '../components/map';

import moment from 'moment';

import Slider from "react-slick";
import ReactPaginate from 'react-paginate';
import more from '../assets/svg/more.svg'


var striptags = require('striptags');

class BlogPage extends Component {
    // constructor(props) {
    //     super(props);

    //     this.state = {
    //         ...props.initialData
    //     };
    // }

    // componentDidMount() {
    //     if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


    //     for (let i = 0; i < this.props.loadData.length; i++) {
    //         this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
    //             this.setState({
    //                 ...data
    //             })
    //         })
    //     }


    // }
    // componentDidUpdate(prevProps) {
    //     if (prevProps[0].location.pathname != this.props[0].location.pathname) {
    //         if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


    //         for (let i = 0; i < this.props.loadData.length; i++) {
    //             this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
    //                 this.setState({
    //                     ...data
    //                 })
    //             })
    //         }
    //     }


    // }

    constructor(props) {
        super(props);
        this.updateStateFromSearch = this.updateStateFromSearch.bind(this);
        this.updateParam = this.updateParam.bind(this);
        this.get = this.get.bind(this);

        this.state = {
            promotedProjects: [],
            page: 0,
            ...props.initialData
        };
    }

    updateStateFromSearch(callback) {
        let broken = this.props[0].location.search.split('?').pop().split('&');
        let params = { page: 0 };
        for (let i = 0; i < broken.length; i++) {
            if (broken[i].indexOf('=') !== -1) {
                params[broken[i].split('=')[0]] = broken[i].split('=')[1];
            }
        }

        this.setState({
            category: null,
            tag: null,
            items: []
        }, () => {
            this.setState(params, callback);

        })

    }


    updateParam(name, value) {

        let broken = this.props[0].location.search.split('?').pop().split('&');
        let params = {};
        for (let i = 0; i < broken.length; i++) {
            if (broken[i].indexOf('=') !== -1) {
                params[broken[i].split('=')[0]] = broken[i].split('=')[1];
            }
        }

        params[name] = value;

        let paramsArr = [];
        for (var key in params) {
            if (params.hasOwnProperty(key) && params[key]) {

                paramsArr.push(key + "=" + params[key]);
            }
        }



        let search = '?' + paramsArr.join('&');


        this.props[0].history.push(this.props[0].location.pathname + search);
        ////console.log(search);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.updateStateFromSearch(() => {
                this.get()
            });
        }
    }

    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }




        this.updateStateFromSearch(this.get);


    }
    get() {
        fetch('https://bioenergo-api.novamedia.agency/blog', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                page: this.state.page,
                lang: this.props.lang
            })
        }).then(res => res.json()).then((result) => {
            //console.log(result);
            this.setState({
                items: result.items,
                total: result.total
            })
        })

    }





    render() {
        return (
            <div className="blog-page">
                <section>
                    <Container fluid className="sub-header">
                        <div className="overlay">
                            <img src={home2} />
                        </div>
                        {/*<div className="sub-header-right">
                        <div className="fb"><a href={Object.translate(this.props.informations, 'facebook', this.props.lang)}><Isvg src={facebook} /></a></div>
                            <div><a href={Object.translate(this.props.informations, 'instagram', this.props.lang)}><Isvg src={instagram} /></a></div>
        </div>*/}
                    </Container>
                </section>
                <section >
                    <Container>
                        <Row className="page-title">
                            <Col lg="12">
                                <h2>{"Obaveštenja".translate(this.props.lang)}</h2>
                                <h3><Link to="/">{"Pocetna".translate(this.props.lang)}</Link> {" > "} {"Obaveštenja".translate(this.props.lang)}</h3>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="page-into">
                    <Container>
                        <Row>
                            <Col lg="12">
                                <h1>{"OBAVEŠTENJA".translate(this.props.lang)}</h1>
                                <h5> {"Novosti i obaveštenja".translate(this.props.lang)}</h5>
                                <h6> {"Obaveštenja iz naše kompanije".translate(this.props.lang)}</h6>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="notices">
                    <Container>
                        <Row>
                           
                            <Row>

                                {
                                    this.state.items && this.state.items.map((item, idx) => {
                                        return (
                                            <Col lg="4">
                                                {/* <BlogArticle {...item} lang={this.props.lang} translate={this.props.translate}></BlogArticle> */}
                                                <Link lang={this.props.lang} to={`/novosti/${item._id}`}>
                                                    <div className="notice">
                                                        <img src={Object.get(item, 'image')} />
                                                        <div className="date">
                                                            <h5>{moment.unix(item && item.timestamp).format('DD.MMMM.YYYY.')}</h5>
                                                        </div>
                                                        <div className="title">
                                                            <h3>{Object.translate(item, 'title', this.props.lang)}</h3>
                                                        </div>
                                                        <div className="content">
                                                            <h4>{striptags(Object.translate(item, 'shortDescription', this.props.lang))}</h4>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </Col>
                                        )
                                    })
                                }



                            </Row>
                            {this.state.total / 9 > 1 ?
                                <Row>
                                    <Col lg="12">
                                        <ReactPaginate
                                            previousLabel={''.translate(this.props.lang)}
                                            nextLabel={''.translate(this.props.lang)}
                                            breakLabel={'...'}
                                            breakClassName={'break-me'}
                                            pageCount={this.state.total / 9}
                                            marginPagesDisplayed={1}
                                            pageRangeDisplayed={2}
                                            onPageChange={(page) => { this.updateParam('page', page.selected) }}
                                            containerClassName={'pagination'}
                                            subContainerClassName={'pages pagination'}
                                            activeClassName={'active'}
                                            hrefBuilder={(page) => { return `?page=${page}` }}
                                        //forcePage={this.state.page}
                                        />

                                    </Col>

                                </Row>

                                :

                                null
                            }
                        </Row>
                    </Container>
                </section>

            </div>
        );
    }
}

export default Page(BlogPage);