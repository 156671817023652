import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import Isvg from 'react-inlinesvg';

import {
    Container,
    Row,
    Col,

} from 'reactstrap';



import logo from '../assets/svg/logo2.svg';
import facebook from '../assets/svg/facebook.svg';
import instagram from '../assets/svg/instagram.svg';

import phone_icon from '../assets/svg/phone-ico.svg';
import mail_icon from '../assets/svg/mail-ico.svg';
import location_icon from '../assets/svg/location-ico.svg';


export class Footer extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    
    render() {
        return (
            <footer>
                <div className="spacer">
                    <hr />
                    <div className="footer-logo"><Isvg src={logo} /></div>
                    <hr />
                </div>
                <Container>
                    <Row>
                        <Col lg="4">
                            <Row className="titles">
                                <Col lg="12">
                                    <span>{"NAVIGACIJA".translate(this.props.lang)}</span>
                                </Col>
                            </Row>
                            <Row className="fields">
                                <Col lg="6">
                                    <div className="links">
                                        <a href="/">{"Početna".translate(this.props.lang)}</a>
                                        <a href="/o-nama">{"O nama".translate(this.props.lang)}</a>
                                        <a href="/galerija">{"Galerija".translate(this.props.lang)}</a>
                                    </div>
                                </Col>
                                <Col lg="6">
                                    <div className="links">
                                        <a href="/novosti">{"Obaveštenja".translate(this.props.lang)}</a>
                                        <a href="/kontakt">{"Kontakt".translate(this.props.lang)}</a>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg="3">
                            <Row className="titles">
                                <Col lg="12">
                                    <span>{"LOKACIJA".translate(this.props.lang)}</span>
                                </Col>
                            </Row>
                            <Row className="fields">
                                <Col lg="12">
                                    <div className="labels">
                                        <p>{Object.translate(this.props.informations, 'address', this.props.lang)}</p>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg="3">
                            <Row className="titles">
                                <Col lg="12">
                                    <span>{"KONTAKT".translate(this.props.lang)}</span>
                                </Col>
                            </Row>
                            <Row className="fields">
                                <Col lg="12">
                                    <div className="labels">
                                        <p>{Object.translate(this.props.informations, 'phoneNumber', this.props.lang)}<br />{Object.translate(this.props.informations, 'email', this.props.lang)}</p>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        {/*<Col lg="2">
                            <Row className="titles">
                                <Col lg="12">
                                    <span>{"PRATITE NAS".translate(this.props.lang)}</span>
                                </Col>
                            </Row>
                            <Row className="fields">
                                <Col lg="12">
                                    <div className="social-networks">
                                        <div className="facebook"><a href={Object.translate(this.props.informations, 'facebook', this.props.lang)}><Isvg src={facebook} /></a></div>
                                        <div><a href={Object.translate(this.props.informations, 'instagram', this.props.lang)}><Isvg src={instagram} /></a></div>
                                    </div>
                                </Col>
                            </Row>
        </Col>*/}
                    </Row>
                    {/* <Row className="titles">
                        <Col lg="4">
                            <span>{"NAVIGACIJA".translate(this.props.lang)}</span>
                        </Col>
                        <Col lg="3">
                            <span>{"LOKACIJA".translate(this.props.lang)}</span>
                        </Col>
                        <Col lg="3">
                            <span>{"KONTAKT".translate(this.props.lang)}</span>
                        </Col>
                        <Col lg="2">
                            <span>{"PRATITE NAS".translate(this.props.lang)}</span>
                        </Col>
                    </Row>
                    <Row className="fields">
                        <Col lg="2">
                            <div className="links">
                                <a href="/">{"Pocetna".translate(this.props.lang)}</a>
                                <a href="/o-nama">{"O nama".translate(this.props.lang)}</a>
                                <a href="/galerija">{"Galerija".translate(this.props.lang)}</a>
                            </div>
                        </Col>
                        <Col lg="2">
                            <div className="links">
                                <a href="/novosti">{"Obavestenja".translate(this.props.lang)}</a>
                                <a href="/kontakt">{"Kontakt".translate(this.props.lang)}</a>
                            </div>
                        </Col>
                        <Col lg="3">
                            <div className="labels">
                                <p>{"Sabac".translate(this.props.lang)}<br />{"Savska 8, Srbija".translate(this.props.lang)}</p>
                            </div>
                        </Col>
                        <Col lg="3">
                            <div className="labels">
                                <p> {"+381 15 306 490".translate(this.props.lang)}<br />{"info@dpklas.rs".translate(this.props.lang)}</p>
                            </div>
                        </Col>
                        <Col lg="2" >
                            <div className="social-networks">
                                <div className="facebook"><Link to="/"><Isvg src={facebook} /></Link></div>
                                <div><Link to="/"><Isvg src={instagram} /></Link></div>
                            </div>
                        </Col>
                    </Row> */}
                    <hr className="footer-hr" />
                    <div className="copyright">
                        <p>Copyright © Bioenergo  - 2020. All Rights Reserved.</p>
                        <p>Created by <span><a href="https://www.novamedia.agency">nova media.</a></span></p>
                    </div>
                </Container>

            </footer>
        )
    }
}

export default Footer;