import React, { Component } from 'react';
//import { Link, Redirect } from 'react-router-dom';
import Link from './link'
import Isvg from 'react-inlinesvg';


import {
    Container,
    Row,
    Col,
    Navbar,
    NavbarBrand,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Nav, NavItem,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption

} from 'reactstrap';
import more from '../assets/svg/more.svg'
import facebook from '../assets/svg/facebook.svg';
import instagram from '../assets/svg/instagram.svg';

import Select from '../components/forms/fields/select'


import facebook_icon from '../assets/svg/facebook-icon.svg'
import instagram_icon from '../assets/svg/instagram-icon.svg'
import phone_icon from '../assets/svg/header-phone.svg'
import mail_icon from '../assets/svg/header-mail.svg'

import hamburger_icon from '../assets/svg/hamburger-icon.svg'
import close_icon from '../assets/svg/close-ico.svg'





import logo from '../assets/svg/logo.svg';
import home from '../assets/images/home.png';
import home2 from '../assets/images/home2.png';







class HomeHeader extends Component {
    constructor(props) {
        super(props);
        this.registerSocketIOEvents = this.registerSocketIOEvents.bind(this);
        this.suggestions = this.suggestions.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.state = {
            suggestions: [],
            search: '',
            imageErrors: {},
            width: 0, height: 0,
            showSearch: false,
            home: 0
        };

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);


    }





    componentDidMount() {
        fetch('https://bioenergo-api.novamedia.agency/services', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',

            }
        }).then((res) => res.json()).then((result) => {
            this.setState({
                services: result
            })
        })

    }

    componentDidUpdate(prevProps) {
        // if (this.props.socketIOClient) {
        //     this.registerSocketIOEvents();
        // }


    }

    registerSocketIOEvents() {
        // if (this.state._registeredEvents)
        //     return;

        // this.setState({
        //     _registeredEvents: true
        // });

        // this.props.socketIOClient.on('suggestions', (data) => {

        //     this.setState({
        //         imageErrors: {},
        //         suggestions: data.products
        //     })
        // });



    }
    updateWindowDimensions() {
        // this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    componentWillUnmount() {
        // document.removeEventListener('mousedown', this.handleClickOutside);
        // window.removeEventListener('resize', this.updateWindowDimensions);

        // if (!this.props.socketIOClient) return;
        // this.props.socketIOClient.removeAllListeners("suggestions");
        //this.props.socketIOClient.removeAllListeners("fetchProductVariations");

    }


    suggestions() {
        // if (this.state.search.length) {
        //     this.props.socketIOClient.emit("suggestions", { search: this.state.search });
        // }
    }
    handleClickOutside(event) {
        // if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
        //     this.setState({
        //         suggestions: []
        //     })
        // }

        // if (this.leftMenuRef && !this.leftMenuRef.contains(event.target)) {
        //     this.setState({
        //         leftMenu: null
        //     })
        // }

        /*if (this.rightMenuRef && !this.rightMenuRef.contains(event.target)) {
            this.setState({
                rightMenu: null
            })
        }*/
    }


    render() {
        return (
            <header>
                <Container>
                    <Row>
                        <Col lg="3" className="logo">
                            <Link to='/'><Isvg src={logo} /></Link>
                        </Col>

                    </Row>

                </Container>
                <button className="hamburger" onClick={() => { this.setState({ mobileMenu: !this.state.mobileMenu }) }}><Isvg src={hamburger_icon} /></button>



                <div className={this.state.mobileMenu ? "header-fields header-fields-open" : "header-fields"} >
                    <button className="close_menu" onClick={() => { this.setState({ mobileMenu: !this.state.mobileMenu }) }}><Isvg src={close_icon} /></button>
                    <div className="header-links">
                        <div className="home">
                            <a href="/" className={this.props[0].location.pathname == '/' ? 'active-page' : ''}>{"Početna".translate(this.props.lang)}</a>
                        </div>
                        <div className="about">
                            {/* <a href="/o-nama"></a> */}
                            <UncontrolledDropdown>
                                <DropdownToggle className="dropdown">
                                    {"O nama".translate(this.props.lang)}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="8.367" viewBox="0 0 14 8.367">
                                        <path id="iconfinder_down-arrow-low-below_2075795" d="M12.865,17.633,7,23.5,1.135,17.633,0,18.805,7,26l7-7.195Z" transform="translate(0 -17.633)" fill="#464749" />
                                    </svg>

                                    {/* <Isvg src={more} className="more" /> */}
                                </DropdownToggle>
                                <DropdownMenu className="menu">
                                    {/* <div className={this.props[0].location.pathname == '/o-nama' ? 'active' : ''} >
                                        <DropdownItem className="drop-item"><Link to="/o-nama">Osnivanje</Link></DropdownItem><hr className="dropmenu-hr" />
                                    </div>
                                    <div className={this.props[0].location.pathname == '/proces-proizvodnje' ? 'active' : ''} >
                                        <DropdownItem className="drop-item"><Link to="/proces-proizvodnje">Proces proizvodnje</Link></DropdownItem><hr className="dropmenu-hr" />
                                    </div>
                                    <div className={this.props[0].location.pathname == '/izvor-sirovina' ? 'active' : ''} >
                                        <DropdownItem className="drop-item"><Link to="/izvor-sirovina">Izvor sirovina</Link></DropdownItem><hr className="dropmenu-hr" />
                                    </div>
                                    <div className={this.props[0].location.pathname == '/proizvodi' ? 'active' : ''} >
                                        <DropdownItem className="drop-item"><Link to="/proizvodi">Proizvodi</Link></DropdownItem><hr className="dropmenu-hr" />
                                    </div>
                                    <div className={this.props[0].location.pathname == '/misija-i-vizija' ? 'active' : ''} >
                                        <DropdownItem className="drop-item"><Link to="/misija-i-vizija">Misija i vizija</Link></DropdownItem><hr className="dropmenu-hr" />
                                    </div> */}
                                    {
                                        this.state.services && this.state.services.map((item, idx) => {
                                            return (
                                                <div className={this.props[0].location.pathname == `/o-nama/${Object.translate(item, 'alias', this.props.lang)}` ? 'active' : ''} >
                                                    <DropdownItem onClick={() => this.setState({mobileMenu: null})} className="drop-item"><Link lang={this.props.lang} to={`/o-nama/${Object.translate(item, 'alias', this.props.lang)}`}>{Object.translate(item, 'name', this.props.lang)}</Link></DropdownItem><hr className="dropmenu-hr" />
                                                </div>
                                            )
                                        })
                                    }

                                    <div className={this.props[0].location.pathname == '/dokumenta' ? 'active' : ''} >
                                        <DropdownItem className="drop-item"><Link lang={this.props.lang} to="/dokumenta">{"Dokumenta".translate(this.props.lang)}</Link></DropdownItem>
                                    </div>

                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>
                        <div className="header-gallery">
                            <Link  lang={this.props.lang}to="/galerija" className={this.props[0].location.pathname == '/galerija' ? 'active-page' : ''}>{"Galerija".translate(this.props.lang)}</Link>
                        </div>
                        <div className="notifications">
                            <Link  lang={this.props.lang} to="/novosti" className={this.props[0].location.pathname == '/novosti' ? 'active-page' : ''}>{"Obaveštenja".translate(this.props.lang)}</Link>
                        </div>
                    </div>
                    <div className="header-btns">
                        <div className="contact-btn">
                            <Link  lang={this.props.lang} to="/kontakt"><button className="contact-button">{"KONTAKT".translate(this.props.lang)}</button></Link>
                        </div>

                        {/*<div className="language">
                            <UncontrolledDropdown>
                                <DropdownToggle className="lang-drop">
                                    SRB
                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="8.367" viewBox="0 0 14 8.367">
                                        <path id="iconfinder_down-arrow-low-below_2075795" d="M12.865,17.633,7,23.5,1.135,17.633,0,18.805,7,26l7-7.195Z" transform="translate(0 -17.633)" fill="#464749" />
                                    </svg>

                                </DropdownToggle>
                                <DropdownMenu className="lang-menu">
                                    <Link to='/'><DropdownItem className="drop-item">SRB</DropdownItem></Link>
                                    <Link to='/en'><DropdownItem className="drop-item">ENG</DropdownItem></Link>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                                </div>*/}
                    </div>
                </div>

                {/* <hr /> */}
                {
                    // this.state.home == 1 ?
                    //     <Container fluid className="home-header">
                    //         <div className="home-overlay">
                    //             <img src={Object.get(this.state.data, 'section[0].image')} />
                    //         </div>

                    //         <div className="header-right">
                    //             <div className="fb"><Link to="/"><Isvg src={facebook} /></Link></div>
                    //             <div><Link to="/"><Isvg src={instagram} /></Link></div>
                    //         </div>
                    //     </Container>
                    //     :
                    //     <Container fluid className="sub-header">
                    //         <div className="overlay">
                    //             <img src={home2} />
                    //         </div>
                    //         <div className="sub-header-right">
                    //             <div className="fb"><Link to="/"><Isvg src={facebook} /></Link></div>
                    //             <div><Link to="/"><Isvg src={instagram} /></Link></div>
                    //         </div>
                    //     </Container>

                }

            </header>

        );
    }
}


export default HomeHeader;
