import React, { Component } from 'react';
import Link from '../components/link';
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';
import Slider from '../components/slider';


import {
    Container,
    Row,
    Col,
} from 'reactstrap';
import Image from '../assets/images/productionProcess.png';




import home2 from '../assets/images/home2.png';

import facebook from '../assets/svg/facebook.svg';
import instagram from '../assets/svg/instagram.svg';



class ProductionProcess extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData
        };
    }

    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }


    }
    componentDidUpdate(prevProps) {
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


            for (let i = 0; i < this.props.loadData.length; i++) {
                this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                    this.setState({
                        ...data
                    }, () => {
                        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                    })
                })
            }
        }


    }




    render() {
        return (
            <div>
                <section>
                    <Container fluid className="sub-header">
                        <div className="overlay">
                            <img src={Object.get(this.state.data, 'image')} />
                        </div>
                       {/* <div className="sub-header-right">
                            <div className="fb"><a href={Object.translate(this.props.informations, 'facebook', this.props.lang)}><Isvg src={facebook} /></a></div>
                            <div><a href={Object.translate(this.props.informations, 'instagram', this.props.lang)}><Isvg src={instagram} /></a></div>
        </div>*/}
                    </Container>
                </section>
                <section >
                    <Container>
                        <Row className="page-title">
                            <Col lg="12">
                                <h2>{Object.translate(this.state.data, 'name', this.props.lang)}</h2>
                                <h3><Link to="/">{"Pocetna".translate(this.props.lang)}</Link> {" > "} {"O nama".translate(this.props.lang)} {" > "} {Object.translate(this.state.data, 'name', this.props.lang)}</h3>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="main-title">
                    <Container>
                        <Row className="page-into">
                            <Col lg="12">

                                <h1>{Object.translate(this.state.data, 'subtitle', this.props.lang)}</h1>
                                <h5>{Object.translate(this.state.data, 'name', this.props.lang)}</h5>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className="production-info">
                    <Container>
                        <Row>
                            {
                                this.state.data && this.state.data.content && this.state.data.content.map((item, idx) => {
                                    if (item.type == 'image') {
                                        return (
                                            <Col lg={{ size: item.width.desktop }} xs={{ size: item.width.mobile }} className="block-section">
                                                <img src={item.value} />
                                            </Col>
                                        )
                                    } else {
                                        return (
                                            <Col lg={{ size: item.width.desktop }} xs={{ size: item.width.mobile }} className="block-section" dangerouslySetInnerHTML={{ __html: item.value && item.value[this.props.lang] }}>
                                            </Col>
                                        )
                                    }
                                })
                            }


                        </Row>
                    </Container>

                </section>



            </div>
        );
    }
}

export default Page(ProductionProcess);