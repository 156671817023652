import React, { Component } from 'react'
import Link from '../components/link';
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';
import Slider from '../components/slider';


import home2 from '../assets/images/home2.png';

import facebook from '../assets/svg/facebook.svg';
import instagram from '../assets/svg/instagram.svg';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';

import pdf from '../assets/svg/pdf.svg'

class Documents extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData
        };
    }

    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }


    }
    componentDidUpdate(prevProps) {
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


            for (let i = 0; i < this.props.loadData.length; i++) {
                this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                    this.setState({
                        ...data
                    }, () => {
                        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                    })
                })
            }
        }


    }
    render() {
        return (
            <div>
                <section>
                    <Container fluid className="sub-header">
                        <div className="overlay">
                            <img src={home2} />
                        </div>
                      {/*  <div className="sub-header-right">
                        <div className="fb"><a href={Object.translate(this.props.informations, 'facebook', this.props.lang)}><Isvg src={facebook} /></a></div>
                            <div><a href={Object.translate(this.props.informations, 'instagram', this.props.lang)}><Isvg src={instagram} /></a></div>
        </div>*/}
                    </Container>
                </section>
                <section>
                    <Container>
                        <Row className="page-title">
                            <Col lg="12">
                                <h2>{'Osnivanje'.translate(this.props.lang)}</h2>
                                <h3><Link to="/">{"Pocetna".translate(this.props.lang)}</Link> {" > "} {'O nama'.translate(this.props.lang)} {" > "} {'Dokumenta'.translate(this.props.lang)}</h3>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="main-title">
                    <Container>
                        <Row className="page-into">
                            <Col lg="12">
                                <h1>{'DOKUMENTA'.translate(this.props.lang)}</h1>
                                <h5>{'Dokumenta'.translate(this.props.lang)}</h5>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="documents">
                    <Container>
                        <Row>
                            <Col lg="12">
                                <h5 className="space40">{'Na ovoj stranici možete preuzeti dokumenta firme Bioenergo PARTNER MD. Dokumenti su u PDF formatu i za njihovo pregledanje potreban vam je program koji omogućava pregledanje .pdf fajlova.'.translate(this.props.lang)}</h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <table>
                                    <tr className="odd">
                                        <td>
                                            <h6>{'Naziv'.translate(this.props.lang)}</h6>
                                        </td>
                                        <td className="right-td">
                                            <h6>{Object.translate(this.state.info, 'name', this.props.lang)}</h6>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <h6>{'Sediste'.translate(this.props.lang)}</h6>
                                        </td>
                                        <td className="right-td">
                                            <h6>{Object.translate(this.state.info, 'place', this.props.lang)}</h6>
                                        </td>
                                    </tr>
                                    <tr className="odd">
                                        <td>
                                            <h6>{'Adresa'.translate(this.props.lang)}</h6>
                                        </td>
                                        <td className="right-td">
                                            <h6>{Object.translate(this.state.info, 'address', this.props.lang)}</h6>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <h6> {'Poslovna jedinica'.translate(this.props.lang)}</h6>
                                        </td>
                                        <td className="right-td">
                                            <h6>{Object.translate(this.state.info, 'businessUnit', this.props.lang)}</h6>
                                        </td>
                                    </tr>

                                    <tr className="odd">
                                        <td>
                                            <h6>{'PIB'.translate(this.props.lang)}</h6>
                                        </td>
                                        <td className="right-td">
                                            <h6>{Object.translate(this.state.info, 'pib', this.props.lang)}</h6>
                                        </td>
                                    </tr>
                                    <tr >
                                        <td>
                                            <h6>{'Maticni broj'.translate(this.props.lang)}</h6>
                                        </td>
                                        <td className="right-td">
                                            <h6>{Object.translate(this.state.info, 'identificationNumber', this.props.lang)}</h6>
                                        </td>
                                    </tr>
                                   {/* <tr className="odd">
                                        <td>
                                            <h6> {'Broj evidencione prijave „ PEPDV“'.translate(this.props.lang)}</h6>
                                        </td>
                                        <td className="right-td">
                                            <h6>{Object.translate(this.state.info, 'pepdv', this.props.lang)}</h6>
                                        </td>
                                    </tr>
    */}
                                    <tr className="odd">
                                        <td>
                                            <h6> {'Ime i prezime ovlašćenog lica'.translate(this.props.lang)}</h6>
                                        </td>
                                        <td className="right-td">
                                            <h6>{Object.translate(this.state.info, 'authorizedPerson', this.props.lang)}</h6>
                                        </td>
                                    </tr>
                                    <tr >
                                        <td>
                                            <h6> {'Telefon'.translate(this.props.lang)}</h6>
                                        </td>
                                        <td className="right-td">
                                            <h6>{Object.translate(this.state.info, 'phoneNumber', this.props.lang)}</h6>
                                        </td>
                                    </tr>
                                
                                   {/* <tr>
                                        <td>
                                            <h6> {'Fax'.translate(this.props.lang)}</h6>
                                        </td>
                                        <td className="right-td">
                                            <h6>{Object.translate(this.state.info, 'fax', this.props.lang)}</h6>
                                        </td>
                                   </tr>*/}
                                    <tr className="odd">
                                        <td>
                                            <h6>{'E-mail'.translate(this.props.lang)}</h6>
                                        </td>
                                        <td className="right-td">
                                            <h6>{Object.translate(this.state.info, 'email', this.props.lang)}</h6>
                                        </td>
                                    </tr>
                                    <tr >
                                        <td>
                                            <h6> {'Web'.translate(this.props.lang)}</h6>
                                        </td>
                                        <td className="right-td">
                                            <h6>{Object.translate(this.state.info, 'web', this.props.lang)}</h6>
                                        </td>
                                    </tr>
                                </table>
                            </Col>
                        </Row>
                        <Row className="company-info">
                            <Col lg="6">
                                <h4 className="space20">
                                     {'Podaci o firmi'.translate(this.props.lang)}
                                </h4>
                                {
                                    this.state.data && this.state.data.map((item, idx) => {
                                        return (
                                            <>
                                                <a href={item.document} target="_blank" download>
                                                    <div className="pdf space20">
                                                        <Isvg src={pdf} className="pdf-icon" /> <h5>{Object.translate(item, 'name', this.props.lang)}</h5>
                                                    </div>
                                                </a>
                                            </>
                                        )
                                    })
                                }

                            </Col>
                            {/* <Col lg="6">
                                <h4 className="space20">
                                    Podaci o firmi
                                </h4>
                                <Link to="/dokumenta">
                                    <div className="pdf space20">
                                        <Isvg src={pdf} className="pdf-icon" /> <h5>Rešenje APR</h5>
                                    </div>
                                </Link>
                                <Link to="/dokumenta">
                                    <div className="pdf space20">
                                        <Isvg src={pdf} className="pdf-icon" /> <h5>PIB</h5>
                                    </div>
                                </Link>
                                <Link to="/dokumenta">
                                    <div className="pdf space20">
                                        <Isvg src={pdf} className="pdf-icon" /> <h5>PDV</h5>
                                    </div>
                                </Link>
                                <Link to="/dokumenta">
                                    <div className="pdf space20">
                                        <Isvg src={pdf} className="pdf-icon" /> <h5>Identifikacija firme</h5>
                                    </div>
                                </Link>
                                <Link to="/dokumenta">
                                    <div className="pdf space20">
                                        <Isvg src={pdf} className="pdf-icon" /> <h5>APR Podaci</h5>
                                    </div>
                                </Link>
                            </Col>
                            <Col lg="6">
                                <h4 className="space20">
                                    Podaci o firmi
                                </h4>
                                <Link to="/dokumenta">
                                    <div className="pdf space20">
                                        <Isvg src={pdf} className="pdf-icon" /> <h5>HACCP</h5>
                                    </div>
                                </Link>
                                <Link to="/dokumenta">
                                    <div className="pdf space20">
                                        <Isvg src={pdf} className="pdf-icon" /> <h5>ISO 9001</h5>
                                    </div>
                                </Link>
                                <Link to="/dokumenta">
                                    <div className="pdf space20">
                                        <Isvg src={pdf} className="pdf-icon" /> <h5>ISO 22000</h5>
                                    </div>
                                </Link>
                            </Col> */}
                        </Row>
                    </Container>
                </section>
            </div>
        )
    }
}

export default Page(Documents)
