import React, { Component } from 'react';
import Link from '../components/link';

import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown
} from 'reactstrap';

import home from '../assets/images/home.png';


import home2 from '../assets/images/home2.png';

import facebook from '../assets/svg/facebook.svg';
import instagram from '../assets/svg/instagram.svg';

import Map from '../components/map';

import moment from 'moment';

var striptags = require('striptags');

class AboutUsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            promotedProjects: [],
            ...props.initialData
        };
    }

    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }



    }


    render() {


        return (
            <div className="about-us">
                <section>
                    <Container fluid className="sub-header">
                        <div className="overlay">
                            <img src={home2} />
                        </div>
                        {/*<div className="sub-header-right">
                            <div className="fb"><Link to="/"><Isvg src={facebook} /></Link></div>
                            <div><Link to="/"><Isvg src={instagram} /></Link></div>
        </div>*/}
                    </Container>
                </section>
                <section >
                    <Container>
                        <Row className="page-title">
                            <Col lg="12">
                                <h2>Osnivanje</h2>
                                <h3>Pocetna {" > "} O nama {" > "} Osnivanje</h3>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="content">
                    <Container>
                        <Row>
                            <Col lg="12">
                                {/* <div className="gallery"> */}
                                <Col lg="12">
                                    <Row>
                                        <Col lg="12">
                                            <h1>
                                                OSNIVANJE
                                                </h1>
                                            <h5 className="space30">
                                                Osnivanje
                                                </h5>
                                        </Col>
                                    </Row>
                                    <Row>
                                        {
                                            this.state.data && this.state.data.content && this.state.data.content.map((item, idx) => {
                                                if (item.type == 'image') {
                                                    return (
                                                        <Col lg={{ size: item.width.desktop }} xs={{ size: item.width.mobile }} className="block-section">
                                                            <img src={item.value} />
                                                        </Col>
                                                    )
                                                } else {
                                                    return (
                                                        <Col lg={{ size: item.width.desktop }} xs={{ size: item.width.mobile }} className="block-section" dangerouslySetInnerHTML={{ __html: item.value && item.value[this.props.lang] }}>
                                                        </Col>
                                                    )
                                                }
                                            })
                                        }

                                    </Row>

                                </Col>
                                {/* </div> */}
                            </Col>
                        </Row>
                    </Container>
                </section>


            </div>
        );
    }
}

export default Page(AboutUsPage);