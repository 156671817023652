import React, { Component } from 'react';
import Link from '../components/link';

import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown
} from 'reactstrap';

import pointer from '../assets/svg/pointer.svg';
import blackPointer from '../assets/svg/blackPointer.svg';

import power from '../assets/svg/power.svg';
import products from '../assets/svg/products.svg';
import resource from '../assets/svg/resource.svg';


import home from '../assets/images/home.png';
import slika1 from '../assets/images/slika1.png';
import slika2 from '../assets/images/slika2.png';
import slika3 from '../assets/images/slika3.png';
import slika4 from '../assets/images/slika4.png';
import facebook from '../assets/svg/facebook.svg';
import instagram from '../assets/svg/instagram.svg';



import Map from '../components/map';

import moment from 'moment';

import Slider from "react-slick";


var striptags = require('striptags');

class HomePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            promotedProjects: [],
            ...props.initialData
        };
    }

    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }
    }







    render() {
        var iOS = false;
        if (typeof navigator !== 'undefined') {
            iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
        }


        return (
            <div>
                <section>
                    <Container fluid className="home-header">
                        <div className="home-overlay">
                            {iOS ?
                                <img src={Object.get(this.state.data, 'section[0].image')}></img>

                                :
                                <video muted autoPlay loop>
                                    <source src={Object.get(this.state.data, 'section[0].video')} type="video/mp4" />
                                </video>

                            }

                        </div>

                       {/* <div className="header-right">
                            <div className="fb"><a href={Object.translate(this.props.informations, 'facebook', this.props.lang)}><Isvg src={facebook} /></a></div>
                            <div><a href={Object.translate(this.props.informations, 'instagram', this.props.lang)}><Isvg src={instagram} /></a></div>
                        </div>*/}
                    </Container>
                </section>

                <section>
                    <Container>
                        <Row>
                            <div className="home-info">
                                <Col lg="8">
                                    <h3>{Object.translate(this.state.data, 'section[0].title', this.props.lang)}</h3>
                                    <h6>{Object.translate(this.state.data, 'section[0].subtitle', this.props.lang)}</h6>
                                </Col>
                                <Col lg="4">

                                    <Link to={Object.translate(this.state.data, 'section[0].buttonLink', this.props.lang)}><button className="home-button">{Object.translate(this.state.data, 'section[0].buttonText', this.props.lang)}<Isvg src={pointer} className="pointer" /></button></Link>
                                </Col>
                            </div>
                        </Row>

                        <Row>
                            <div className="circles">
                                <Col lg="4" className="mb">
                                    <div className="photo">
                                        <Isvg src={Object.get(this.state.data, 'section[1].image1')} />
                                    </div>
                                    <h3>{Object.translate(this.state.data, 'section[1].title1', this.props.lang)}</h3>
                                    <h6>{Object.translate(this.state.data, 'section[1].subtitle1', this.props.lang)}</h6>
                                </Col>
                                <Col lg="4" className="mb">
                                    <div className="photo">
                                        <Isvg src={Object.get(this.state.data, 'section[1].image2')} />
                                    </div>
                                    <h3>{Object.translate(this.state.data, 'section[1].title2', this.props.lang)}</h3>
                                    <h6>{Object.translate(this.state.data, 'section[1].subtitle2', this.props.lang)}</h6>
                                </Col>
                                <Col lg="4">
                                    <div className="photo">
                                        <Isvg src={Object.get(this.state.data, 'section[1].image3')} />
                                    </div>
                                    <h3>{Object.translate(this.state.data, 'section[1].title3', this.props.lang)}</h3>
                                    <h6>{Object.translate(this.state.data, 'section[1].subtitle3', this.props.lang)}</h6>
                                </Col>
                            </div>

                        </Row>
                        <hr className="horizontal-rule" />

                        <Row>
                            <div className="gallery">
                                <Col lg="12">
                                    <Row>
                                        <Col lg="12">
                                            <h1>
                                                {"GALERIJA".translate(this.props.lang)}
                                            </h1>
                                            <h5>
                                                {"Foto galerija".translate(this.props.lang)}
                                            </h5>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="6">
                                            <div className="image1">
                                                <img src={Object.get(this.state.gallery, 'images[0]')} />
                                            </div>
                                        </Col>
                                        <Col lg="6">
                                            <Row>
                                                <Col lg="6">
                                                    <div className="image2">
                                                        <img src={Object.get(this.state.gallery, 'images[1]')} />
                                                    </div>
                                                </Col>
                                                <Col lg="6">
                                                    <div className="image3">
                                                        <img src={Object.get(this.state.gallery, 'images[2]')} />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="12">
                                                    <div className="image4">
                                                        <img src={Object.get(this.state.gallery, 'images[3]')} />
                                                    </div>
                                                </Col>
                                            </Row>

                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg="12">
                                            <Link lang={this.props.lang} to="/galerija"><button className="gallery-button">{"Foto galerija".translate(this.props.lang)} <Isvg src={blackPointer} className="black-pointer" /></button></Link>
                                        </Col>
                                    </Row>
                                </Col>
                            </div>
                        </Row>
                    </Container>
                </section>

            </div>
        );
    }
}

export default Page(HomePage);