import React, { Component } from 'react';
import Link from '../components/link';
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';



import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown
} from 'reactstrap';

import pin from '../assets/svg/pin.svg';
import phone from '../assets/svg/phone.svg';
import email from '../assets/svg/email.svg';

import Form from '../components/forms/messageForm'

import home2 from '../assets/images/home2.png';

import facebook from '../assets/svg/facebook.svg';
import instagram from '../assets/svg/instagram.svg';
import Map from '../components/map';


class ContactPage extends Component {
    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);

        this.state = {
            ...props.initialData
        };
    }

    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }


    }
    componentDidUpdate(prevProps) {
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


            for (let i = 0; i < this.props.loadData.length; i++) {
                this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                    this.setState({
                        ...data
                    }, () => {
                        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                    })
                })
            }
        }

    }
    submit(data) {
        // console.log(data);
        this.setState({
            formLoading: true
        }, () => {

            fetch('https://bioenergo-api.novamedia.agency/contact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',

                },
                body: JSON.stringify(
                    data
                )
            }).then((res) => res.json()).then((result) => {
                this.setState({
                    //formLoading: null,
                    formDone: true
                })
            })
        })
    }


    render() {

        return (
            <div className="contact">
                <section>
                    <Container fluid className="sub-header">
                        <div className="overlay">
                            <img src={home2} />
                        </div>
                      {/*  <div className="sub-header-right">
                        <div className="fb"><a href={Object.translate(this.props.informations, 'facebook', this.props.lang)}><Isvg src={facebook} /></a></div>
                            <div><a href={Object.translate(this.props.informations, 'instagram', this.props.lang)}><Isvg src={instagram} /></a></div>
        </div>*/}
                    </Container>
                </section>
                <section >
                    <Container>
                        <Row className="page-title">
                            <Col lg="12">
                                <h2>{"Kontakt".translate(this.props.lang)}</h2>
                                <h3><Link to="/">{"Pocetna".translate(this.props.lang)}</Link> {" > "} {"Kontakt".translate(this.props.lang)}</h3>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="page-into">
                    <Container>
                        <Row>
                            <Col lg="12">
                                <h1>{"KONTAKT".translate(this.props.lang)}</h1>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="contact-info">
                    <Container>
                        <Row>
                            <Col lg="4">

                                <h3>{"Kontakt informacije".translate(this.props.lang)}</h3>
                                <h4>{Object.translate(this.state.data, 'name', this.props.lang)}</h4>
                                <div className="info">
                                    <div className="icons">
                                        <Isvg src={pin} />
                                    </div>
                                    <div className="address">
                                        <h5>{Object.translate(this.state.data, 'place', this.props.lang)}</h5>
                                        <h5>{Object.translate(this.state.data, 'address', this.props.lang)}</h5>
                                    </div>
                                </div>
                                <div className="info">
                                    <div className="icons">
                                        <Isvg src={phone} />
                                    </div>
                                    <h6>{Object.translate(this.state.data, 'phoneNumber', this.props.lang)}</h6>
                                </div>
                                <div className="info">
                                    <div className="icons">
                                        <Isvg src={email} />
                                    </div>
                                    <h5>{Object.translate(this.state.data, 'email', this.props.lang)}</h5>
                                </div>

                            </Col>

                            <Col lg="8">
                                <Form onSubmit={this.submit} />
                            </Col>
                        </Row>
                        <Row>
                            <Map {...this.props} coords={this.state.data && this.state.data.coords } />
                        </Row>
                    </Container>
                </section>

            </div>
        );
    }
}

export default Page(ContactPage);