import React, { Component } from 'react'
import Link from '../components/link';

import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import slika1 from '../assets/images/slika1.png';
import slika2 from '../assets/images/slika2.png';
import slika3 from '../assets/images/slika3.png';
import slika4 from '../assets/images/slika4.png';

import home2 from '../assets/images/home2.png';

import facebook from '../assets/svg/facebook.svg';
import instagram from '../assets/svg/instagram.svg';
import close_ico from '../assets/svg/close_ico.svg';


import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown
} from 'reactstrap';


class GalleryPage extends Component {
    constructor(props) {
        super(props)
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);

        this.state = {
            ...props.initialData,
            gallery: [],
            activeIndex: 0

        }
    }


    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }




    }
    componentDidUpdate(prevProps) {
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


            for (let i = 0; i < this.props.loadData.length; i++) {
                this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                    this.setState({
                        ...data
                    }, () => {
                        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                    })
                })
            }
        }


    }

    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === this.state.gallery.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? this.state.gallery.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }

    render() {
        let arr = [];

        for (let i = 0; i < this.state.gallery.length; i++) {
            arr.push([this.state.gallery[i]])

            let array = [];

            for (let j = i + 1; j < i + 4; j++) {
                array.push(this.state.gallery[j]);
            }
            arr.push(array);
            array = [];
            i = i + 3;
            for (let j = i + 1; j < i + 4; j++) {
                array.push(this.state.gallery[j]);
            }
            arr.push(array);
            i = i + 4;
            arr.push([this.state.gallery[i]])
        }
        const { activeIndex } = this.state;
        const slides = this.state.gallery && this.state.gallery.map((item) => {
            return (
                <CarouselItem
                    tag="div"
                    key={item}
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                >
                    <div className="lightbox-item">
                        <img src={item} />
                    </div>

                </CarouselItem>
            );
        });
        return (
            <div className="gallery">
                <section>
                    <Container fluid className="sub-header">
                        <div className="overlay">
                            <img src={home2} />
                        </div>
                       {/* <div className="sub-header-right">
                            <div className="fb"><a href={Object.translate(this.props.informations, 'facebook', this.props.lang)}><Isvg src={facebook} /></a></div>
                            <div><a href={Object.translate(this.props.informations, 'instagram', this.props.lang)}><Isvg src={instagram} /></a></div>
        </div>*/}
                    </Container>
                </section>
                <section >
                    <Container>
                        <Row className="page-title">
                            <Col lg="12">
                                <h2>{"Galerija".translate(this.props.lang)}</h2>
                                <h3><Link to="/">{"Pocetna".translate(this.props.lang)}</Link> {" > "} {"Galerija".translate(this.props.lang)}</h3>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="page-into">
                    <Container>
                        <Row>
                            <Col lg="12">
                                <h1>{"GALERIJA".translate(this.props.lang)}</h1>
                                <h5>{"Foto galerija".translate(this.props.lang)}</h5>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section>
                    <Container>
                        <Row className="gallery-images">

                            {
                                arr.map((item, idx) => {
                                    if (item.length == 1 && item[0]) {
                                        if (item[0])
                                            return (
                                                <Col lg="6" className="img1">
                                                    <img src={item[0]} onClick={() => {
                                                        let index = 0;
                                                        for (let i = 0; i < idx; i++) {
                                                            index += arr[i].length;
                                                        }

                                                        this.setState({ lightbox: true, activeIndex: index })
                                                    }} />
                                                </Col>
                                            )
                                    } else {
                                        return (
                                            <Col lg="6" className="no-padding">
                                                <Container>
                                                    <Row>
                                                        {
                                                            item.map((sub, sidx) => {
                                                                if (sub)
                                                                    return (
                                                                        <Col lg={sidx == 0 || sidx == 1 ? 6 : 12} className={sidx == 0 || sidx == 1 ? "img2" : "img3"}>
                                                                            <img src={sub} onClick={() => {
                                                                                let index = 0;
                                                                                for (let i = 0; i < idx; i++) {
                                                                                    index += arr[i].length;
                                                                                }
                                                                                index += sidx;


                                                                                this.setState({ lightbox: true, activeIndex: index })
                                                                            }} />
                                                                        </Col>
                                                                    )
                                                            })
                                                        }
                                                    </Row>
                                                </Container>
                                            </Col>

                                        )
                                    }
                                })
                            }


                            {/* <Col lg="6">
                                <div className="image1">
                                    <img src={slika1} />
                                </div>
                            </Col>
                            <Col lg="6">
                                <Row>
                                    <Col lg="6">
                                        <div className="image2">
                                            <img src={slika2} />
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        <div className="image3">
                                            <img src={slika3} />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="12">
                                        <div className="image4">
                                            <img src={slika4} />
                                        </div>
                                    </Col>
                                </Row>
                        
                            </Col> */}
                        </Row>


                    </Container>
                </section>
                {this.state.lightbox ?
                    <div className="lightbox">
                        <div className="close" onClick={() => this.setState({ lightbox: null })}>
                            <Isvg src={close_ico} />
                        </div>
                        <Carousel
                            activeIndex={activeIndex}
                            next={this.next}
                            previous={this.previous}
                            autoPlay={null}
                        >
                            {slides}
                            <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                            <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                        </Carousel>

                    </div>
                    : null
                }

            </div>
        )
    }
}

export default Page(GalleryPage)
