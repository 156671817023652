import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';


import {
    Container,
    Row,
    Col,
    Navbar,
    NavbarBrand,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Nav, NavItem,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption

} from 'reactstrap';
import more from '../assets/svg/more.svg'
import Select from '../components/forms/fields/select'


import facebook_icon from '../assets/svg/facebook-icon.svg'
import instagram_icon from '../assets/svg/instagram-icon.svg'
import phone_icon from '../assets/svg/header-phone.svg'
import mail_icon from '../assets/svg/header-mail.svg'

import hamburger_icon from '../assets/svg/hamburger-icon.svg'

import logo from '../assets/svg/logo.svg';
import home from '../assets/images/home.png';





class HomeHeader extends Component {


    render() {
        return (
            <header>
                <Container className="menubar">
                    <Row>
                        <Col lg="3" className="logo">
                            <Link to='/'><Isvg src={logo} /></Link>
                        </Col>
                        <Col lg="7">
                            <div className="header-fields">
                                <div className="home">
                                    <a href="/">Pocetna</a>
                                </div>
                                <div className="about">
                                    {/* <a href="/o-nama"></a> */}
                                    <UncontrolledDropdown>
                                        <DropdownToggle className="dropdown">
                                            O nama <Isvg src={more} className="more" />
                                        </DropdownToggle>
                                        <DropdownMenu className="menu">
                                            <DropdownItem className="drop-item"><Link to="/o-nama">Osnivanje</Link></DropdownItem><hr className="dropmenu-hr" />
                                            <DropdownItem className="drop-item"><Link to="/proces-proizvodnje">Proces proizvodnje</Link></DropdownItem><hr className="dropmenu-hr" />
                                            <DropdownItem className="drop-item"><Link to="/izvor-sirovina">Izvor sirovina</Link></DropdownItem><hr className="dropmenu-hr" />
                                            <DropdownItem className="drop-item"><Link to="/proizvodi">Proizvodi</Link></DropdownItem><hr className="dropmenu-hr" />
                                            <DropdownItem className="drop-item"><Link to="/misija-i-vizija">Misija i vizija</Link></DropdownItem><hr className="dropmenu-hr" />
                                            <DropdownItem className="drop-item"><Link to="/dokumenta">Dokumenta</Link></DropdownItem><hr className="dropmenu-hr" />


                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </div>
                                <div className="galery">
                                    <a href="/galerija">Galerija</a>
                                </div>
                                <div className="notifications">
                                    <a href="/novosti">Obavestenja</a>
                                </div>
                                <div className="contact-btn">
                                    <Link to="/kontakt"><button className="contact-button">KONTAKT</button></Link>
                                </div>
                                {/* <div className="language">
                                    <Select value="0">
                                        <option value="0">SRB</option>
                                        <option value="1">ENG</option>
                                    </Select>
                                </div> */}
                            </div>
                        </Col>
                    </Row>

                </Container>
                <hr />
                {/* <section className="header-image">
                    <Container>
                        <Row>
                            <Col lg="12">
                                <img src={home} />
                            </Col>
                        </Row>
                    </Container>
                </section> */}
                <Container fluid className="sub-header">
                    <div className="overlay">
                        <img src={home} />
                    </div>
                    {/* <Container>
                        <Row className="info">
                            <Col md="7">
                                <h1 className="title">{this.props.page}</h1>
                            </Col>
                            <Col md="5">
                                <ul className="breadcrumb">
                                    <li><a>Startseite</a></li>
                                    <li><a>{this.props.page}</a></li>
                                </ul>
                            </Col>
                        </Row>

                    </Container> */}
                </Container>
            </header>

        );
    }
}


export default HomeHeader;
