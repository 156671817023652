import React, { Component } from 'react';
import Link from '../components/link';
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';
import Slider from '../components/slider';


import {
    Container,
    Row,
    Col,
} from 'reactstrap';

import slika5 from '../assets/images/slika5.png';
import slika from '../assets/images/slika.png';
import home2 from '../assets/images/home2.png';

import facebook from '../assets/svg/facebook.svg';
import instagram from '../assets/svg/instagram.svg';

import moment from 'moment';
var striptags = require('striptags');



class BlogDetailPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData
        };
    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }


    }
    componentDidUpdate(prevProps) {
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


            for (let i = 0; i < this.props.loadData.length; i++) {
                this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                    this.setState({
                        ...data
                    }, () => {
                        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                    })
                })
            }
        }


    }


    render() {

        return (
            <div className="blog-page">
                <section>
                    <Container fluid className="sub-header">
                        <div className="overlay">
                            <img src={Object.get(this.state.data, 'image')} />
                        </div>
                        {/*<div className="sub-header-right">
                        <div className="fb"><a href={Object.translate(this.props.informations, 'facebook', this.props.lang)}><Isvg src={facebook} /></a></div>
                            <div><a href={Object.translate(this.props.informations, 'instagram', this.props.lang)}><Isvg src={instagram} /></a></div>
        </div>*/}
                    </Container>
                </section>
                <section >
                    <Container>
                        <Row className="page-title">
                            <Col lg="12">
                                <h2>{"Obavestenja".translate(this.props.lang)}</h2>
                                <h3><Link to="/">{"Pocetna".translate(this.props.lang)}</Link> {" > "} {"Obavestenja".translate(this.props.lang)}</h3>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section>
                    <Container>
                        <Row>
                            <Col lg="8">
                                <div className="detail">
                                    {/* <img src={slika} /> */}
                                    <img src={Object.get(this.state.data, 'image')} />
                                    <h2>{Object.translate(this.state.data, 'title', this.props.lang)}</h2>
                                    <h5>
                                        {moment.unix(this.state.data && this.state.data.timestamp).format('DD.MMMM.YYYY.')}

                                    </h5>
                                    <p>
                                        {striptags(Object.translate(this.state.data, 'content', this.props.lang))}
                                    </p>
                                </div>
                            </Col>
                            <Col lg="4" className="notices">
                                <h1> {"Najnovija obavestenja".translate(this.props.lang)} </h1>
                                {/* <Link to="/novosti/5">
                                    <div className="notice">
                                        <img src={slika5} />
                                        <div className="date">
                                            <h5>12. januar 2020.</h5>
                                        </div>
                                        <div className="title">
                                            <h3>Trenutno sređujemo određene funkcionalnosti</h3>
                                        </div>
                                        <div className="content">
                                            <h4>Tekst obaveštenja iz naše kompanije na pocetnoj stranici...</h4>
                                        </div>
                                    </div>
                                </Link>
                                <Link to="/novosti/4">
                                    <div className="notice">
                                        <img src={slika5} />
                                        <div className="date">
                                            <h5>12. januar 2020.</h5>
                                        </div>
                                        <div className="title">
                                            <h3>Trenutno sređujemo određene funkcionalnosti</h3>
                                        </div>
                                        <div className="content">
                                            <h4>Tekst obaveštenja iz naše kompanije na pocetnoj stranici...</h4>
                                        </div>
                                    </div>
                                </Link> */}
                                {
                                    this.state.latestBlog && this.state.latestBlog.map((item, idx) => {
                                        return (
                                            <Link lang={this.props.lang} to={`/novosti/${item._id}`}>
                                                <div className="notice">
                                                    <img src={Object.get(item, 'image')} />
                                                    <div className="date">
                                                        <h5>{moment.unix(item && item.timestamp).format('DD.MMMM.YYYY.')}</h5>
                                                    </div>
                                                    <div className="title">
                                                        <h3>{Object.translate(item, 'title', this.props.lang)}</h3>
                                                    </div>
                                                    <div className="content">
                                                        <h4>{striptags(Object.translate(item, 'shortDescription', this.props.lang))}</h4>
                                                    </div>
                                                </div>
                                            </Link>
                                        )
                                    })
                                }
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
        );
    }
}

export default Page(BlogDetailPage);