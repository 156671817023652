import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'

import Text from './fields/text';
import Textarea from './fields/textarea';
import pointer from '../../assets/svg/pointer.svg';
import Isvg from 'react-inlinesvg';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';

const required = value => value ? undefined : "Required"

const renderTextField = ({
    input,
    label,
    placeholder,
    meta: { touched, error },
    type
}) => (

        <Text
            placeholder={placeholder}
            errorText={touched && error}
            label={label}
            error={touched && error}
            type={type}
            {...input}
        />
    )

const renderTextareaField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
}) => (

        <Textarea
            placeholder={placeholder}
            label={label}
            errorText={touched && error}
            error={touched && error}
            {...input}
        />
    )


class form extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }



    render() {

        const { handleSubmit, pristine, reset, submitting } = this.props;
       // console.log(pristine, submitting);

        return (
            <form onSubmit={handleSubmit}>
                <div className="message-form">
                    <div>
                        <h2> {"Pošaljite nam poruku".translate(this.props.lang)}</h2>
                    </div>
                    <Row>
                        <Col lg="6">
                            <div className="field-wrap mb">
                                <Field
                                    name="name"
                                    component={renderTextField}
                                    placeholder={"Ime i prezime *".translate(this.props.lang)} //
                                    validate={[required]}
                                ></Field>
                            </div>
                        </Col>
                        <Col lg="6">

                            <div className="space-down field-padding mb">
                            <Field
                                name="email"
                                component={renderTextField}
                                placeholder={"Email *".translate(this.props.lang)} //
                                validate={[required]}
                            ></Field>
                            </div>
                        </Col>

                        <Col lg="12">

                            <div className="field-wrap field-padding mb">
                                <Field
                                    name="message"
                                    component={renderTextareaField}
                                    placeholder={"Vasa poruka *".translate(this.props.lang)}//
                                    validate={[required]}
                                ></Field>
                            </div>
                        </Col>

                        <Col lg="12">
                            <button className="button">{'POŠALJI PORUKU'.translate(this.props.lang)}<Isvg src={pointer} className="pointer"/></button>
                            {/*  */}
                        </Col>
                    </Row>
                </div>


            </form>

        )
    }
}

export default reduxForm({
    form: 'form'  // a unique identifier for this form
})(form)
